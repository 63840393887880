import React, { Fragment, useMemo } from 'react';

import useDynamicMediaQuery from '@/hooks/useDynamicMediaQuery';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import useIsPublicApp from '@/hooks/useIsPublicApp';
import ListingActions from '@/pages/ListingDetails/ListingActions';
import { TemplateAttributeKey as AttrKey, TemplateAttributeValue } from '@/types';
import { isIndustrialOrPark } from '@/utilities/constants';
import { ternaryOperation } from '@/utilities/functions';
import { useIsAuthenticated } from '@azure/msal-react';
import { Typography } from '@components/Typography';
import { addDays, format, isBefore } from 'date-fns';
import { PrimaryInfoElements, PrimaryInfoProps } from './PrimaryInfo.types';
import { addChildrenToElement, primarySpecElement } from './PrimaryInfo.utils';
import PrimarySpecification from './PrimarySpecification';

const PrimaryInfo: React.FC<PrimaryInfoProps> = ({
  propertyId,
  attributes,
  propertyType,
  isRouteHistoryStackEmpty,
  isSaved,
  downloading,
  setShowShareModal,
  setShowRemoveFavoritesModal,
  handleAddingFavoriteListing,
  handleDownloadFlyer,
}) => {
  const below368Pixels = useDynamicMediaQuery('(min-width: 360px) and (max-width: 368px)');
  const isAuthenticated = useIsAuthenticated();
  const isBigScreen = useIsBigScreen();
  const isPublicApp = useIsPublicApp();

  const initialElements: PrimaryInfoElements = {
    // wraps property name and full address
    propertyNameAddress: <div className="flex-col items-start gap-1">{[]}</div>,
    // wraps property primary image
    propertyPrimaryImage: <div>{[]}</div>,
    propertyDateAvailable: <>{[]}</>,
    propertyBuildingStatus: <>{[]}</>,
    // wraps property primary specifications
    propertySpecs: <div className="grid md:grid-cols-1 lg:grid-cols-2">{[]}</div>,
    propertyModifiedAt: <>{[]}</>,
    propertyId: <>{[]}</>,
  };

  const assignPrimaryInfo = (
    attribute: TemplateAttributeValue,
    primaryInfo: PrimaryInfoElements,
    element: JSX.Element,
  ) => {
    if (attribute.key === AttrKey.FullAddress || attribute.key === AttrKey.Name) {
      primaryInfo.propertyNameAddress = addChildrenToElement(
        element,
        primaryInfo.propertyNameAddress,
      );
    } else if (attribute.key === AttrKey.DigitalAssets) {
      primaryInfo.propertyPrimaryImage = addChildrenToElement(
        element,
        primaryInfo.propertyPrimaryImage,
      );
    } else if (attribute.key === AttrKey.BuildingStatus) {
      primaryInfo.propertyBuildingStatus = addChildrenToElement(
        element,
        primaryInfo.propertyBuildingStatus,
      );
    } else if (!isPublicApp && attribute.key === AttrKey.DateAvailable) {
      primaryInfo.propertyDateAvailable = addChildrenToElement(
        element,
        primaryInfo.propertyDateAvailable,
      );
      let label = attribute.label ?? '';
      let dateAvailable =
        attribute.value === 'CONTACT_US' ? <div>Contact Us</div> : attribute.value;

      if (isIndustrialOrPark(propertyType)) {
        label =
          attribute.value === 'Now' && attribute.value !== null
            ? 'Available Date'
            : 'Potentially Available Date';
        dateAvailable = ternaryOperation(
          !attribute.value || attribute.value === 'CONTACT_US',
          'Contact Us',
          ternaryOperation(
            isBefore(new Date(attribute.rawValue), addDays(new Date(), 1)),
            'Now',
            format(new Date(attribute.rawValue), 'MM/dd/yyyy'),
          ),
        );
      }

      primaryInfo.propertySpecs = addChildrenToElement(
        <PrimarySpecification
          key={attribute.key}
          label={label}
          value={dateAvailable}
          propertyType={propertyType}
        />,
        primaryInfo.propertySpecs,
      );
    } else if (attribute.key === AttrKey.ModifiedAt) {
      primaryInfo.propertyModifiedAt = addChildrenToElement(
        element,
        primaryInfo.propertyModifiedAt,
      );
    } else if (attribute.key === AttrKey.PropertyId) {
      primaryInfo.propertyId = addChildrenToElement(element, primaryInfo.propertyId);
    } else if (attribute.key === AttrKey.UnitSquareFootage) {
      primaryInfo.propertySpecs = addChildrenToElement(
        <PrimarySpecification
          key={attribute.key}
          label={attribute.label ?? ''}
          value={attribute.value}
          propertyType={propertyType}
        />,
        primaryInfo.propertySpecs,
      );
    } else {
      primaryInfo.propertySpecs = addChildrenToElement(element, primaryInfo.propertySpecs);
    }

    return primaryInfo;
  };

  const primaryInfo = useMemo(
    () =>
      attributes?.reduce((primaryInfo, attribute) => {
        const element = (
          <Fragment key={`${attribute.key}`}>
            {primarySpecElement(attribute, isPublicApp, propertyType, below368Pixels)}
          </Fragment>
        );

        return assignPrimaryInfo(attribute, primaryInfo, element);
      }, initialElements),
    [below368Pixels],
  );

  return (
    <div className="flex items-start justify-between lg:max-w-[71.25rem] md:max-w-[45rem] px-15px md:py-6 w-full">
      <div className="flex flex-col gap-4 lg:min-w-[70%] md:min-w-[60%] sm:min-w-[100%]">
        <div className="hidden md:flex row">
          {primaryInfo?.propertyBuildingStatus}
          {!isIndustrialOrPark(propertyType) && primaryInfo?.propertyDateAvailable}
        </div>
        <div className="hidden md:block">{primaryInfo?.propertyNameAddress}</div>
        {primaryInfo?.propertySpecs}
        {isAuthenticated && (
          <div>
            <div className="bg-slate-100 h-[1px] md:w-[93%] mb-4"></div>
            <div className="flex lg:items-center sm:items-start lg:flex-row sm:flex-col">
              {primaryInfo?.propertyModifiedAt}
              {primaryInfo?.propertyId}
            </div>
            {!isBigScreen && <div className="bg-slate-100 h-[1px] md:w-[93%] my-4"></div>}
          </div>
        )}
        {isBigScreen && (
          <ListingActions
            listingID={propertyId}
            isRouteHistoryStackEmpty={isRouteHistoryStackEmpty}
            isSaved={isSaved}
            setShowShareModal={setShowShareModal}
            setShowRemoveFavoritesModal={setShowRemoveFavoritesModal}
            handleAddingFavoriteListing={handleAddingFavoriteListing}
            handleDownloadFlyer={handleDownloadFlyer}
            downloading={downloading}
          />
        )}
      </div>
      <div className="flex flex-col gap-4">
        <Typography className="hidden md:block" variant={'h5'}>
          Gallery
        </Typography>
        <div className="hidden md:block">{primaryInfo?.propertyPrimaryImage}</div>
      </div>
    </div>
  );
};

export default PrimaryInfo;
