import cn from 'classnames';
import React from 'react';

import { TemplateAttributeKey as AttrKey, TemplateAttributeValue } from '@/types';
import { ConstrainedTypography } from '@components/ConstrainedTypography';
import { Typography } from '@components/Typography';
import PrimarySpecification from './PrimarySpecification';
import ThumbnailWrapper from './ThumbnailWrapper';

export const addChildrenToElement = (element: JSX.Element, primaryInfoElement: JSX.Element) =>
  React.cloneElement(primaryInfoElement, {}, primaryInfoElement.props.children.concat(element));

// returns required element for provided property primary specification argument
export const primarySpecElement = (
  attribute: TemplateAttributeValue,
  isPublicApp: boolean,
  propertyType?: string,
  below368Pixels?: boolean,
) => {
  switch (attribute.key) {
    case AttrKey.FullAddress:
      return (
        <Typography
          variant="body-2"
          className="sm:mb-[2.125rem] md:mb-0 text-slate-300 !font-light !leading-5">
          {attribute.value}
        </Typography>
      );
    case AttrKey.Name:
      return (
        <ConstrainedTypography
          className="!font-semibold"
          variant="h3"
          width="lg:max-w-[40.625rem] md:max-w-[94%]">
          {attribute.value}
        </ConstrainedTypography>
      );
    case AttrKey.DigitalAssets:
      return <ThumbnailWrapper thumbnailUrl={attribute.value} assetUrls={attribute.rawValue} />;
    case AttrKey.BuildingStatus: {
      let buildingStatus, pillColor;
      switch (attribute.rawValue) {
        case 'BUILD_TO_SUIT':
          buildingStatus = 'build to suit opportunity';
          pillColor = 'bg-solar-700';
          break;
        case 'NEW_SPECULATIVE_DEVELOPMENT':
          buildingStatus = isPublicApp ? 'new development' : 'new class a development';
          pillColor = 'bg-solar-700';
          break;
        case 'UNDER_CONSTRUCTION':
          buildingStatus = isPublicApp ? 'new development' : 'under construction';
          pillColor = 'bg-solar-700';
          break;
        case 'Operational Industrial':
          buildingStatus = isPublicApp ? 'existing' : 'industrial';
          pillColor = 'bg-rust-600';
          break;
        case 'Link Parks':
          buildingStatus = !isPublicApp && 'link parks';
          pillColor = 'bg-future-300';
          break;
      }
      return buildingStatus ? (
        <Typography
          variant="body-4"
          className={cn(
            '!text-[12px] !leading-[14px] !tracking-[1.5px] !font-medium mr-2 rounded-[40px]  uppercase px-3 p-[0.5rem]',
            pillColor,
          )}>
          {buildingStatus}
        </Typography>
      ) : (
        <></>
      );
    }
    case AttrKey.DateAvailable:
      return <></>;
    case AttrKey.ModifiedAt:
      return (
        <div className="flex items-center lg:w-1/2 sm:w-full">
          <Typography
            className={
              'flex basis-1/2 grow-0 items-center md:font-light md:leading-5 md:text-[14px]  sm:font-normal sm:text-[12px] sm:leading-4'
            }
            variant="body-3">
            Last Updated:
          </Typography>
          <Typography variant="body-3" className="!font-medium !leading-5 max-w-[10rem]">
            {attribute.value}
          </Typography>
        </div>
      );
    case AttrKey.PropertyId:
      return (
        <div className="flex items-center lg:w-1/2 sm:w-full">
          <Typography
            className={
              'flex basis-1/2 grow-0 items-center md:font-light md:leading-5 md:text-[14px]  sm:font-normal sm:text-[12px] sm:leading-4'
            }
            variant="body-3">
            Property ID:
          </Typography>
          <Typography variant="body-3" className="!font-medium !leading-5 max-w-[10rem]">
            {attribute.value}
          </Typography>
        </div>
      );
    case AttrKey.MarketDescription:
      return <></>;
    case AttrKey.Market:
    case AttrKey.Submarket:
    case AttrKey.SquareFootage:
    default: // If no case attribute is available, will default here until required case attribute is added
      return (
        <PrimarySpecification
          label={isPublicApp && attribute.label === 'Submarket' ? 'Location' : attribute.label!}
          value={attribute.value}
        />
      );
  }
};
