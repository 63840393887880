import Button, { ButtonShape, ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import cn from 'classnames';

import useDeviceType from '@/hooks/useDeviceType';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import { useIsAuthenticated } from '@azure/msal-react';
import Spinner from '@components/LoadingSpinner/Spinner';
import { useEffect, useState } from 'react';
import './ListingDetails.css';

export interface ListingActionsProps {
  listingID: any;
  isRouteHistoryStackEmpty: any;
  isSaved: any;
  setShowShareModal: (arg0: boolean) => void;
  setShowRemoveFavoritesModal: (arg0: boolean) => void;
  handleAddingFavoriteListing: (arg0: any) => void;
  handleDownloadFlyer: () => void;
  downloading: boolean;
}
const ListingActions: React.FC<ListingActionsProps> = ({
  listingID,
  isRouteHistoryStackEmpty,
  isSaved,
  setShowShareModal,
  setShowRemoveFavoritesModal,
  handleAddingFavoriteListing,
  handleDownloadFlyer,
  downloading,
}) => {
  const deviceType = useDeviceType();
  const [favIcon, setFavIcon] = useState(isSaved ? IconName.STAR_FILLED : IconName.STAR);
  const [downloadIcon, setDownloadIcon] = useState(
    downloading ? <Spinner /> : <Icon name={IconName.DOWNLOAD} />,
  );
  const isAuthenticated = useIsAuthenticated();
  const isBigScreen = useIsBigScreen();
  const getLabel = () => {
    if (deviceType === 'click') {
      return isSaved ? 'Remove from Favorites' : 'Add to Favorites';
    }
    return '';
  };

  useEffect(() => {
    setDownloadIcon(downloading ? <Spinner /> : <Icon name={IconName.DOWNLOAD} />);
  }, [downloading]);

  useEffect(() => {
    isSaved ? setFavIcon(IconName.STAR_FILLED) : setFavIcon(IconName.STAR);
  }, [isSaved]);
  return (
    <div
      className={cn([
        'flex sm:justify-end md:justify-start items-center sm:space-x-0 md:space-x-4',
        isRouteHistoryStackEmpty || isBigScreen ? 'w-full' : 'w-1/2',
      ])}>
      <Button
        buttonClassNames={cn([isBigScreen ? '!border-[0px]' : ''])}
        shape={ButtonShape.CIRCLE}
        Icon={downloadIcon}
        label="Download Flyer"
        onClick={() => handleDownloadFlyer()}
        variant={isBigScreen ? ButtonVariant.PRIMARY_ONE : ButtonVariant.REVEAL_ACTION}
      />

      <Button
        labelClassNames={cn([isBigScreen ? '!leading-[1.5rem]' : ''])}
        shape={ButtonShape.CIRCLE}
        Icon={<Icon name={IconName.SHARE} />}
        label={deviceType === 'click' ? 'Share Listing' : undefined}
        onClick={() => setShowShareModal(true)}
        variant={ButtonVariant.REVEAL_ACTION}
      />
      {isAuthenticated && (
        <Button
          labelClassNames={cn([isBigScreen ? '!leading-[1.5rem]' : ''])}
          shape={ButtonShape.CIRCLE}
          Icon={<Icon name={favIcon} />}
          label={getLabel()}
          onClick={() => {
            if (isSaved) {
              setShowRemoveFavoritesModal(true);
            } else {
              handleAddingFavoriteListing(listingID);
            }
          }}
          onMouseOver={() => {
            if (deviceType === 'click') {
              isSaved ? setFavIcon(IconName.STAR) : setFavIcon(IconName.STAR_FILLED);
            }
          }}
          onMouseOut={() => {
            if (deviceType === 'click') {
              isSaved ? setFavIcon(IconName.STAR_FILLED) : setFavIcon(IconName.STAR);
            }
          }}
          variant={ButtonVariant.REVEAL_ACTION}
        />
      )}
    </div>
  );
};

export default ListingActions;
