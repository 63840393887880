import { Typography } from '@components/Typography';
import React from 'react';
import { AttributeValueType } from './ListingDetails.utils';
type MarketDescriptionProps = {
  market: AttributeValueType;
  marketDescription: AttributeValueType;
};
const MarketDescription: React.FC<MarketDescriptionProps> = ({ market, marketDescription }) => {
  return (
    <div>
      {marketDescription !== null && (
        <div className="flex flex-col gap-4">
          <Typography variant="h4" className="">
            {`The ${market} Market`}
          </Typography>
          <Typography variant="body-1" className="">
            {marketDescription as string}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default MarketDescription;
