import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { MAP_3D_ZOOM_THRESHOLD, MAP_FLY_ZOOM } from '@/config';
import {
  AVAILABLE_SQ_FT,
  useAvailableSqFtButtonLabel,
  useAvailableSqFtButtonLabelExternal,
} from '@/hooks/useAvailableSqFtButtonLabel';
import useFlyToLocation from '@/hooks/useFlyToLocation';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import useIsPublicApp from '@/hooks/useIsPublicApp';
import { useGeolocation } from '@/pages/Map/hooks/useGeolocation';
import { useMapActions } from '@/pages/Map/hooks/useMapActions';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import { ActiveFilterQueryParam } from '@/types';
import { CURRENT_LOCATION_STRING } from '@/utilities/constants';
import { useIsAuthenticated } from '@azure/msal-react';
import AvailableSqFtFilterModal from '@components/AvailableSqFtFilterModal';
import Button from '@components/Button';
import { ButtonVariant } from '@components/Button/Button.types';
import { Icon, IconName } from '@components/Icon';
import {
  DatePicker,
  DatePickerMode,
  DatePickerSelectedDate,
  DatePickerTimeFrames,
  DropdownSelector,
  DropdownSelectorOption,
} from '@components/Inputs';
import Link, { LinkVariant } from '@components/Link';
import SearchInput from '@components/Map/SearchInput';
import MapFilterModalButton from '@components/MapFilterModalButton';
import MoreFiltersModal from '@components/MoreFiltersModal';
import { availableSqFtOptions } from '@components/MoreFiltersModal/SquareFootage/constants';
import SaveSearchModal from '@components/SaveSearchModal';
import { Typography } from '@components/Typography';
import { getUnixTime } from 'date-fns';
import './MapFilterHeader.css';

export const MapFilterHeader: React.FC = () => {
  const isBigScreen = useIsBigScreen();
  const isAuthenticated = useIsAuthenticated();
  const [showSaveSearchButton, setShowSaveSearchButton] = useState(isAuthenticated);
  const [showClearFiltersButton, setShowClearFiltersButton] = useState(false);
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);

  const { flyToLocation } = useFlyToLocation();
  const { updateCurrentLocation } = useGeolocation();
  const {
    dateAvailable,
    dateTimeFrame,
    unitSquareFootageAvailableRange,
    clearHeightRange,
    marketIds,
    submarketIds,
    listingTypes,
    loadingDocksRange,
    searchedLocation,
    classifications,
    accountOwnerships,
    railServed,
    trailerParking,
    outdoorStorage,
    esfr,
    fund,
    clearAllFilters,
    updateSearchParameters,
    clearFilters,
    listings,
    setSelectedListing,
    setSelectedOption,
    setSearchedLocation,
    resetDatePicker,
    updateDateAvailable,
    updateDateTimeFrame,
    setDistanceOption,
    distanceFilter,
  } = useMapData();
  const { reset } = useMapActions();
  const availableSqFtButtonLabel = useAvailableSqFtButtonLabel(unitSquareFootageAvailableRange);
  const availableSqFtButtonLabelExternal = useAvailableSqFtButtonLabelExternal(
    unitSquareFootageAvailableRange,
  );
  const isPublicApp = useIsPublicApp();

  useEffect(() => {
    const hasActiveFilters =
      unitSquareFootageAvailableRange.min ||
      unitSquareFootageAvailableRange.max ||
      dateAvailable ||
      dateTimeFrame ||
      clearHeightRange.min ||
      clearHeightRange.max ||
      loadingDocksRange.min ||
      loadingDocksRange.max ||
      !!marketIds.length ||
      !!submarketIds.length ||
      !!listingTypes.length ||
      !!classifications.length ||
      !!accountOwnerships.length ||
      railServed === true ||
      searchedLocation ||
      trailerParking === true ||
      outdoorStorage === true ||
      esfr === true ||
      fund;

    setShowSaveSearchButton((isAuthenticated && hasActiveFilters) as boolean);
    setShowClearFiltersButton((hasActiveFilters || distanceFilter) as boolean);
  }, [
    unitSquareFootageAvailableRange.min,
    unitSquareFootageAvailableRange.max,
    dateAvailable,
    dateTimeFrame,
    clearHeightRange.min,
    clearHeightRange.max,
    loadingDocksRange.min,
    loadingDocksRange.max,
    marketIds,
    submarketIds,
    listingTypes,
    classifications,
    accountOwnerships,
    railServed,
    searchedLocation,
    trailerParking,
    outdoorStorage,
    esfr,
    fund,
    distanceFilter,
    isAuthenticated,
  ]);

  const handleDatePickerOnSelect = (selection: DatePickerSelectedDate, mode: DatePickerMode) => {
    if (!selection) {
      updateSearchParameters([
        [ActiveFilterQueryParam.DATE_TIMEFRAME, ''],
        [ActiveFilterQueryParam.DATE_AVAILABLE, ''],
      ]);
      resetDatePicker();
    } else {
      switch (mode) {
        case DatePickerMode.TIMEFRAME:
          if (selection === '180') {
            let currentDate = new Date();
            let nextSixMonthDate = new Date();
            nextSixMonthDate.setMonth(nextSixMonthDate.getMonth() + 6);

            const totalDays = Math.round(
              (nextSixMonthDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),
            );

            updateSearchParameters([
              [ActiveFilterQueryParam.DATE_TIMEFRAME, totalDays ?? ''],
              [ActiveFilterQueryParam.DATE_AVAILABLE, ''],
            ]);
          } else {
            updateSearchParameters([
              [ActiveFilterQueryParam.DATE_TIMEFRAME, (selection as DatePickerTimeFrames) ?? ''],
              [ActiveFilterQueryParam.DATE_AVAILABLE, ''],
            ]);
          }
          updateDateAvailable(undefined);
          break;
        case DatePickerMode.DATE:
          updateSearchParameters([
            [ActiveFilterQueryParam.DATE_AVAILABLE, getUnixTime(selection as Date)],
            [ActiveFilterQueryParam.DATE_TIMEFRAME, ''],
          ]);
          updateDateTimeFrame(undefined);
          break;
      }
    }
  };
  const updateMinMaxValues = (minMaxValues: string[]) => {
    updateSearchParameters([
      [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX, minMaxValues[1]],
      [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN, minMaxValues[0]],
    ]);
    unitSquareFootageAvailableRange.max = minMaxValues[1];
    unitSquareFootageAvailableRange.min = minMaxValues[0];
  };
  const handleSqFtDropdownOnSelect = (selection: DropdownSelectorOption) => {
    const minMaxValues: string[] = `${selection.value}`.split('-');
    switch (selection.label) {
      case '':
        updateSearchParameters([
          [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX, `${selection.value}`],
          [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN, `${selection.value}`],
        ]);
        unitSquareFootageAvailableRange.max = `${selection.value}`;
        unitSquareFootageAvailableRange.min = `${selection.value}`;
        break;
      case '0 - 25,000 SF':
        updateSearchParameters([
          [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX, `${selection.value}`],
          [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN, ''],
        ]);
        unitSquareFootageAvailableRange.max = `${selection.value}`;
        unitSquareFootageAvailableRange.min = '';
        break;
      case '25,000 - 75,000 SF':
        updateMinMaxValues(minMaxValues);
        break;
      case '75,000 - 150,000 SF':
        updateMinMaxValues(minMaxValues);
        break;
      case '150,000 - 300,000 SF':
        updateMinMaxValues(minMaxValues);
        break;
      case '300,000 - 500,000 SF':
        updateMinMaxValues(minMaxValues);
        break;
      case '500,000+ SF':
        updateSearchParameters([
          [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX, ''],
          [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN, `${selection.value}`],
        ]);
        unitSquareFootageAvailableRange.max = '';
        unitSquareFootageAvailableRange.min = `${selection.value}`;
    }
  };

  return (
    <div className="flex justify-center bg-cement-100">
      <div
        className={cn([
          'flex flex-col gap-4 items-start  md:justify-between md:py-4 md:pl-4 sm:py-3 sm:px-3 w-full',
          isPublicApp ? 'max-w-[71.25rem]  sm:p-4 md:px-15px' : '',
        ])}>
        {isPublicApp && (
          <div className="flex flex-row ">
            <Link url={'https://linklogistics.com/'}>
              <Typography
                variant="body-3"
                className="!font-light hover:text-slate-500 hover:!font-semibold cursor-pointer">
                Home
              </Typography>
            </Link>
            <Typography variant="body-3" className="!font-light mx-1">
              /
            </Typography>
            <Typography variant="body-3" className="!font-semibold">
              Search Available Properties
            </Typography>
          </div>
        )}
        <div className="flex flex-row items-center justify-between w-full">
          <div
            className={cn(
              'flex sm:flex-1 lg:flex-initial sm:w-full',
              isPublicApp && 'sm:flex-col md:flex-row sm:mb-[1rem] md:mb-0',
            )}>
            <SearchInput
              classNames="map-search-input sm:flex-1 lg:flex-initial"
              clearFilters={clearFilters}
              dropdownHeight={40}
              showMyLocation
              onMyLocation={async () => {
                updateSearchParameters([
                  [ActiveFilterQueryParam.SEARCHED_LOCATION, CURRENT_LOCATION_STRING],
                  [ActiveFilterQueryParam.INITIAL_VIEW_STATE, null],
                  [ActiveFilterQueryParam.MARKET_IDS, null],
                  [ActiveFilterQueryParam.SUBMARKET_IDS, null],
                ]);
                const currentLocation = await updateCurrentLocation();
                if (!currentLocation) {
                  updateSearchParameters([[ActiveFilterQueryParam.SEARCHED_LOCATION, null]]);
                  return;
                }
                const { longitude, latitude } = currentLocation;
                setSearchedLocation(CURRENT_LOCATION_STRING);
                setDistanceOption({
                  optionIndex: -1,
                  category: 'locations',
                  center: [longitude, latitude],
                  boundingBox: null,
                  displayName: 'Current Location',
                  listingId: null,
                  types: ['currentLocation'],
                });
                flyToLocation([longitude, latitude], MAP_FLY_ZOOM);
              }}
              onSelect={(selectedOption) => {
                if (!selectedOption) {
                  setSelectedListing(null);
                  setSelectedOption(null);
                  return;
                }

                if (selectedOption.category === 'markets') {
                  return;
                }

                const bounds = selectedOption.boundingBox ?? selectedOption.center;
                let zoom: number | undefined;

                switch (selectedOption.category) {
                  case 'locations': {
                    setSelectedOption(selectedOption);
                    zoom = MAP_FLY_ZOOM;
                    setDistanceOption(selectedOption);
                    break;
                  }

                  case 'listingNames': {
                    setSelectedOption(selectedOption);
                    const listing = listings.find((x) => String(x.id) === selectedOption.listingId);
                    setSelectedListing(listing ?? null);
                    setDistanceOption(selectedOption);
                    zoom = MAP_3D_ZOOM_THRESHOLD;
                    break;
                  }

                  case 'propertyAddresses': {
                    setSelectedOption(selectedOption);
                    setDistanceOption(selectedOption);
                    setSelectedListing(null);

                    zoom = MAP_3D_ZOOM_THRESHOLD;
                    break;
                  }
                }

                flyToLocation(bounds, zoom);
              }}
              selection={searchedLocation}
            />
            {isAuthenticated && (
              <DatePicker
                classNames="map-date-input"
                disablePastDays
                onSelect={(selection, mode) => handleDatePickerOnSelect(selection, mode)}
                placeholder={'Date Available'}
                selectedDate={dateAvailable}
                selectedTimeFrame={dateTimeFrame}
              />
            )}
            {!isPublicApp && (
              <MapFilterModalButton
                buttonLabel={availableSqFtButtonLabel}
                classNames="map-filter-modal"
                disableMousedownEventListener
                Modal={AvailableSqFtFilterModal}
                onRemoveInputValueClearingPill={() => {
                  updateSearchParameters([
                    [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX, ''],
                    [ActiveFilterQueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN, ''],
                  ]);
                }}
                showInputValueClearingPill={availableSqFtButtonLabel !== AVAILABLE_SQ_FT}
                sqFtRange={unitSquareFootageAvailableRange}
                maxWidth="max-w-[6.94rem]"
              />
            )}
            {isPublicApp && (
              <div className={cn('sm:flex', isPublicApp && 'sm:pt-[1rem] md:pt-0')}>
                <DropdownSelector
                  onSelect={(selectedOption) => {
                    const option = selectedOption as DropdownSelectorOption;
                    handleSqFtDropdownOnSelect(option);
                  }}
                  clearFilters={clearFilters}
                  options={availableSqFtOptions}
                  selectedOption={
                    unitSquareFootageAvailableRange.max != '' ||
                    unitSquareFootageAvailableRange.min != ''
                      ? {
                          label: availableSqFtButtonLabelExternal,
                          value: availableSqFtButtonLabelExternal,
                        }
                      : undefined
                  }
                  classNames="sm:flex-1 !h-12 map-sqft-filter-external"
                  isValuePill={true}
                  placeholder={AVAILABLE_SQ_FT}
                  maxWidth="sm:max-w-[9rem] md:max-w-[6.94rem]"
                />
                <MapFilterModalButton
                  classNames="map-more-filters-external"
                  buttonLabel="More"
                  Modal={MoreFiltersModal}
                  showFilterCount
                  isFilterButton
                />
              </div>
            )}
            {!isPublicApp && (
              <MapFilterModalButton
                classNames="map-more-filters"
                buttonLabel="More"
                Modal={MoreFiltersModal}
                showFilterCount
                isFilterButton
              />
            )}

            {showClearFiltersButton && (
              <div className="flex flex-col justify-between map-clear-filters">
                <Link
                  classNames="!text-xs"
                  onClick={() => {
                    clearAllFilters();
                    if (!distanceFilter) {
                      reset();
                    }
                  }}
                  variant={LinkVariant.DEFAULT}>
                  <Icon name={IconName.REFRESH} classNames="p-1" />
                  Clear Filters
                </Link>
              </div>
            )}
          </div>
          {showSaveSearchButton && (
            <div className="flex h-12">
              <div className="ml-2 map-search-button">
                <Button
                  buttonClassNames={cn([
                    '!px-4',
                    showSaveSearchModal && '!border-freight-100 !text-freight-100 !h-12',
                  ])}
                  label={isBigScreen ? 'Save Search' : ''}
                  Icon={<Icon name={IconName.BOOKMARK} />}
                  onClick={() => {
                    setShowSaveSearchModal(!showSaveSearchModal);
                  }}
                  variant={ButtonVariant.SAVE_SEARCH}
                />
              </div>
            </div>
          )}
        </div>

        {showSaveSearchModal && (
          <SaveSearchModal
            onClose={() => {
              setShowSaveSearchModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MapFilterHeader;
