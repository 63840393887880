import cn from 'classnames';
import { format } from 'date-fns';
import React, { useMemo } from 'react';

import { FEATURE_FLAG_ENABLE_RESULT_PANEL_AVAILABILITY_REPORT } from '@/config';
import { useCart } from '@/hooks/useReportCart';
import { useUserFavorites } from '@/hooks/useUserFavorites';
import { useMapActions } from '@/pages/Map/hooks/useMapActions';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import { formatNumberWithCommas } from '@/utilities/textHelpers';
import { ConstrainedTypography } from '@components/ConstrainedTypography';
import { Icon, IconName } from '@components/Icon';
import TransparentCheckbox from '@components/Inputs/TransparentCheckbox';
import Thumbnail from '@components/Thumbnail/Thumbnail';
import { Typography } from '@components/Typography/Typography';
import { SearchResultCardProps } from './SearchResultCard.types';

import useIsPublicApp from '@/hooks/useIsPublicApp';
import { useToast } from '@/hooks/useToast';
import { ActiveFilterQueryParam as SearchParameterNames } from '@/types';
import { PropertyType, isIndustrialOrPark, isiOS } from '@/utilities/constants';
import { useIsAuthenticated } from '@azure/msal-react';
import { useMediaQuery } from 'react-responsive';
import { Tooltip } from 'react-tooltip';
import './SearchResultCard.css';
import { ternaryOperation } from '@/utilities/functions';

const SearchResultCard: React.FC<SearchResultCardProps> = ({
  acreage,
  address,
  clearHeight,
  dateAvailable,
  dockDoors,
  driveInDoors,
  id,
  imageUrl,
  buildingStatus,
  onFavoriteSelect,
  onResultSelect,
  propertySize,
  propertyType,
  handleRemoveFavorite,
  propertyName,
  unitName,
  listingViewCount,
}) => {
  const isPublicApp = useIsPublicApp();
  const { selectedListing, listings, selectedOption } = useMapData();
  const { secondaryHover, setSecondaryHover, setResultCardHover } = useMapActions();
  const { addFavorite, favorites, isFavorite, pendingFavorites, updatePendingFavorites } =
    useUserFavorites();
  const { addToast } = useToast();

  const searchParameter = new URLSearchParams(location.search).get(
    SearchParameterNames.INITIAL_VIEW_STATE,
  )!;
  const searchParameterParts = searchParameter && searchParameter.split('|');

  const isSelectedListing =
    selectedListing?.id === id ||
    (searchParameterParts &&
      searchParameterParts[3] === 'listingNames' &&
      searchParameterParts[2] === id);

  const isPropertyAddressSelected =
    selectedOption?.category === 'propertyAddresses' ||
    (searchParameterParts && searchParameterParts[3] === 'propertyAddresses');
  const searchedPropertyAddress = new URLSearchParams(location.search).get(
    SearchParameterNames.SEARCHED_LOCATION,
  )!;
  const isSearchedPropertyAddress =
    selectedOption?.displayName === address || searchedPropertyAddress === address;
  const isSecondaryHover = secondaryHover?.id === id;

  const { addToCart, removeFromCart, isAdded } = useCart();
  const isSaved = useMemo(() => isFavorite(id), [favorites]);

  const ios = isiOS();
  const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMediumAndBigScreen = useMediaQuery({ query: '(min-width: 768px)' });
  const isSmallAndMediumScreen = useMediaQuery({ query: '(max-width: 1023px)' });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const isAuthenticated = useIsAuthenticated();

  const showFavortiteToast = (
    listingId: string,
    description: string,
    title: string,
    type: 'success' | 'error' | 'warning' | 'info',
  ) => {
    addToast({
      id: 'generate-flyer-' + listingId,
      description: description,
      title: title,
      type: type,
    });
  };

  const handleClickOnStar = () => {
    if (isSaved) {
      handleRemoveFavorite(id);
    } else {
      updatePendingFavorites([...pendingFavorites, id]);
      addFavorite(id).catch((err) => {
        showFavortiteToast(id, 'Try again', 'Listing Not Favorited', 'error');
        updatePendingFavorites(pendingFavorites.filter((f) => f !== id));
      });
    }
  };

  const availableTime = useMemo(() => {
    // BTS
    if (!dateAvailable || dateAvailable === 'CONTACT_US') {
      return <div className="mr-[1rem]">Contact us for availability</div>;
    } else if (
      dateAvailable === 'Now' ||
      dateAvailable === 'Vacant' ||
      new Date(dateAvailable) <= new Date()
    ) {
      return <div className="mr-[1rem]">Available Now</div>;
    } else if (
      buildingStatus === 'BUILD_TO_SUIT' ||
      buildingStatus === 'NEW_SPECULATIVE_DEVELOPMENT' ||
      buildingStatus === 'UNDER_CONSTRUCTION'
    ) {
      const value = new Date(dateAvailable);
      const quarter = Math.ceil((value.getMonth() + 1) / 3);
      return (
        <div className="mr-[1rem]">
          Available Q{quarter} ‘{format(value, 'yy')}
        </div>
      );
    } else if (
      // Industrial or Parks
      isIndustrialOrPark(propertyType)
    ) {
      const timeSplit = dateAvailable.split('T');
      const dateString = timeSplit[0];
      const dateSplit = dateString.split('-');
      const expectedFormat = dateSplit[1] + '/' + dateSplit[2] + '/' + dateSplit[0];
      return <div className="mr-[1rem]">Potentially Available {expectedFormat}</div>;
    } else {
      return '';
    }
  }, []);

  const formatBuildingStatus = (
    value: string | undefined,
  ): { _buildingStatus?: string; pillColor?: string } => {
    if (value === undefined) return {};

    let _buildingStatus: string | undefined;
    let pillColor: string | undefined;
    switch (value) {
      case 'BUILD_TO_SUIT':
        _buildingStatus = 'build to suit opportunity';
        pillColor = 'bg-solar-700';
        break;
      case 'NEW_SPECULATIVE_DEVELOPMENT':
        _buildingStatus = isPublicApp ? 'new development' : 'new class a development';
        pillColor = 'bg-solar-700';
        break;
      case 'UNDER_CONSTRUCTION':
        _buildingStatus = isPublicApp ? 'new development' : 'under construction';
        pillColor = 'bg-solar-700';
        break;
      case 'Operational Industrial':
        _buildingStatus = isPublicApp ? 'existing' : 'industrial';
        pillColor = 'bg-rust-100';
        break;
      case 'Link Parks':
        _buildingStatus = !isPublicApp ? 'link parks' : undefined;
        pillColor = 'bg-future-300';
        break;
    }
    return { _buildingStatus, pillColor };
  };

  const { _buildingStatus, pillColor } = formatBuildingStatus(buildingStatus);

  const showListingViewCount = () => {
    // Disable Views count on Card. Ref: https://linklogistics.atlassian.net/browse/MAR-2767
    return false;
  };

  const updateCart = (id: string, name: string) => {
    isAdded({ listingId: id, listingName: name })
      ? removeFromCart({ listingId: id, listingName: name })
      : addToCart({ listingId: id, listingName: name });
  };
  return (
    <div key={id}>
      <div className="absolute flex justify-between card-options">
        {FEATURE_FLAG_ENABLE_RESULT_PANEL_AVAILABILITY_REPORT && (
          <span className="ml-4 z-[1] p-3">
            {isSmallAndMediumScreen ? (
              <input
                type="checkbox"
                id="myCheckbox"
                checked={isAdded({ listingId: id, listingName: propertyName })}
                onChange={() => updateCart(id, propertyName)}
                className="tablet-checkbox w-[22px] h-[22px] bg-white-500/[.80] border-white border-1"
              />
            ) : (
              <TransparentCheckbox
                isChecked={isAdded({ listingId: id, listingName: propertyName })}
                onCheck={() => {
                  addToCart({ listingId: id, listingName: propertyName });
                }}
                onUnCheck={() => {
                  removeFromCart({ listingId: id, listingName: propertyName });
                }}
              />
            )}
          </span>
        )}
        {!isBigScreen && isAuthenticated && (
          <span
            className="mt-3 cursor-pointer "
            onClick={(e) => {
              e.stopPropagation();
              handleClickOnStar();
            }}>
            {isSaved || pendingFavorites.includes(id) ? (
              <Icon name={IconName.STAR_FILLED} />
            ) : (
              <Icon classNames={cn(['fav-icon'])} name={IconName.STAR} />
            )}
          </span>
        )}
      </div>

      <div
        className={cn([
          'result-card relative',
          isSelectedListing && 'selected',
          !isSelectedListing &&
            isPropertyAddressSelected &&
            isSearchedPropertyAddress &&
            'selected',
          isSecondaryHover && 'secondary-hover',
          'flex lg:flex-row flex-col sm:min-h-[253px] md:min-h-full',
        ])}
        onMouseEnter={() => {
          const listing = listings.find((x) => x.id === id) ?? null;
          setResultCardHover(listing);
          if (selectedListing && listing?.id !== selectedListing.id) {
            setSecondaryHover(listing);
          }
          !isBigScreen && listing?.id && ios && onResultSelect(listing.id.toString());
        }}
        onMouseLeave={() => {
          setResultCardHover(null);
          setSecondaryHover(null);
        }}
        onClick={(e) => {
          onResultSelect(id);
        }}>
        <div className="result-card-image relative h-36 lg:h-36 w-full lg:w-[40%]">
          <Thumbnail imgClassNames="h-36 lg:h-36 w-full lg:w-72" propertyImageUrl={imageUrl}>
            <>
              {showListingViewCount() && (
                <span
                  className={cn([
                    'absolute inline-flex items-center p-3 lg:p-0 top-20 right-0 sm:top-20 sm:right-0 lg:top-1 lg:right-3',
                  ])}>
                  <Icon classNames="w-4 h-4 pr-[0.35rem]" name={IconName.EYE} />
                  <span className="text-xs text-cement-100"> {listingViewCount} Views </span>
                </span>
              )}

              {_buildingStatus != undefined && (
                <div className={cn('building-status', pillColor)}>
                  <Typography
                    variant="label-2"
                    className={cn(
                      '!leading-[14px] !tracking-[1px]',
                      buildingStatus === 'Operational Industrial'
                        ? '!text-white-100'
                        : '!text-slate-500',
                    )}>
                    {_buildingStatus}
                  </Typography>
                </div>
              )}
            </>
          </Thumbnail>

          {FEATURE_FLAG_ENABLE_RESULT_PANEL_AVAILABILITY_REPORT && (
            <>
              <div className="absolute top-0 left-0 w-full h-1/3 bg-gradient-to-b from-slate-200 to-transparent"></div>
              <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full"></div>
            </>
          )}
        </div>

        <div className="result-card-info-container">
          {!isPublicApp && (
            <div className="flex justify-between">
              {availableTime !== '' && (
                <Typography className="available-time" variant="subtitle-3">
                  {availableTime}
                </Typography>
              )}
            </div>
          )}
          {isPublicApp && isMediumAndBigScreen && (
            <>
              {propertyType === PropertyType.PRE_DEVELOPMENT ? (
                <div className="md:mb-3 lg:mb-0">
                  <Typography variant="subtitle-3" className={'property-item font-bold leading-5'}>
                    {acreage
                      ? formatNumberWithCommas(acreage, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : '- '}
                    <span className="text-sm font-bold ml-[0.15rem]">Acres</span>
                  </Typography>
                </div>
              ) : (
                <div>
                  <Typography variant="subtitle-3" className="font-bold leading-5 property-item">
                    {propertySize?.value ? formatNumberWithCommas(propertySize.value) : '-'}
                    <span className="text-sm font-bold ml-[0.15rem]">
                      {propertySize?.unitOfMeasure!}
                    </span>
                  </Typography>
                </div>
              )}
            </>
          )}
          <div className="result-name-address-container">
            <ConstrainedTypography
              className="text-slate-500 lg:pb-1 md:!text-[16px] md:!leading-6 md:!font-normal sm:!leading-5 sm:!font-medium"
              variant="subtitle-2"
              width="max-w-[95%]">
              {propertyName}
            </ConstrainedTypography>

            <ConstrainedTypography
              className="text-cement-500 !text-[12px]"
              variant="body-4"
              width="max-w-[95%]">
              {address}
            </ConstrainedTypography>
          </div>
          <div className={'justify-between attributes-container !w-full'}>
            {unitName && (
              <div>
                <Typography variant="body-4" className="property-item !text-[12px]">
                  <span className="attribute-title mr-[0.15rem]">Unit Name: </span>
                  {unitName ?? '--'}
                </Typography>
              </div>
            )}
            {ternaryOperation(
              (isPublicApp && isMobileScreen) || !isPublicApp,
              propertyType === PropertyType.PRE_DEVELOPMENT ? (
                <div className="md:mb-3 lg:mb-0">
                  <Typography variant="body-4" className="property-item !text-[12px]">
                    {acreage
                      ? formatNumberWithCommas(acreage, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : '- '}
                    <span className="attribute-title ml-[0.15rem]">Acres</span>
                  </Typography>
                </div>
              ) : (
                <div className={!unitName ? '!w-full' : ''}>
                  <Typography variant="body-4" className="property-item !text-[12px]">
                    <span className="attribute-title mr-[0.15rem]">Available Size:</span>
                    {propertySize?.value ? formatNumberWithCommas(propertySize.value) : '-'}
                    <span className="!ml-[0.15rem]">{propertySize?.unitOfMeasure}</span>
                  </Typography>
                </div>
              ),
              null,
            )}
          </div>
        </div>

        {isBigScreen && isAuthenticated && (
          <div className="fav absolute right-[0.5rem] top-[0.5rem]">
            <span
              data-tooltip-id={`tooltip-${id}`}
              data-tooltip-place="left"
              className="cursor-pointer lg:mt-3 lg:mr-3"
              onClick={(e) => {
                e.stopPropagation();
                handleClickOnStar();
              }}>
              {isSaved || pendingFavorites.includes(id) ? (
                <Icon name={IconName.STAR_FILLED} />
              ) : (
                <Icon classNames={cn(['fav-icon'])} name={IconName.STAR} />
              )}
            </span>
            <Tooltip id={`tooltip-${id}`} place="left">
              <Typography variant="body-5" className="max-w-[12.5rem]">
                {isSaved ? 'Remove from Favorites' : 'Add to Favorites'}
              </Typography>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResultCard;
