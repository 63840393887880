export enum IconName {
  ADD_FOLDER = 'addFolder',
  ALERT_CIRCLE = 'alertCircle',
  ALERT_TRIANGLE = 'alertTriangle',
  ARROW_LEFT = 'arrowLeft',
  ARROW_RIGHT = 'arrowRight',
  BOOKMARK = 'bookmark',
  CALENDAR = 'calendar',
  CENTER = 'center',
  CHECK_BOX = 'checkBox',
  CHECK_MARK = 'checkMark',
  CHEVRON_DOWN = 'chevronDown',
  CHEVRON_LEFT = 'chevronLeft',
  CHEVRON_RIGHT = 'chevronRight',
  CHEVRON_UP = 'chevronUp',
  CLOCK = 'clock',
  CLOSE = 'close',
  CLOSE_LARGE = 'closeLarge',
  COLLECTION_MAP_PIN = 'collectionMapPin',
  COLLECTION_PIN = 'collectionPin',
  COMPS = 'comps',
  CURRENT_LOCATION = 'currentLocation',
  DATA_LAYERS = 'dataLayers',
  DOT = 'dot',
  DOWNLOAD = 'download',
  EDIT = 'edit',
  EXTERNAL_LINK = 'externalLink',
  EXPORT_MEDIUM = 'exportMedium',
  FACEBOOK = 'facebook',
  FACILITY = 'facility',
  FILE_PLUS = 'filePlus',
  FILTER = 'filter',
  FINANCIAL_AND_INVESTMENT = 'financialAndInvestment',
  FOLDER = 'folder',
  FULL_SCREEN_BUTTON = 'fullScreenButton',
  HASH_MARK = 'hashMark',
  HOME = 'home',
  INSTAGRAM = 'instagram',
  LEASING_AND_OCCUPANCY = 'leasingAndOccupancy',
  LINK = 'link',
  LIST = 'list',
  LINKEDIN = 'linkedin',
  LIVE_LOCATION = 'liveLocation',
  MAIL = 'mail',
  MORE_VERTICAL = 'moreVertical',
  MAP_SETTINGS = 'mapSettings',
  MEDIA = 'media',
  MENU = 'menu',
  NAVIGATION = 'navigation',
  NAVIGATOR = 'navigator',
  NOTIFICATION = 'notifications',
  PAUSE_BUTTON = 'pauseButton',
  PERCENT = 'percent',
  PHONE = 'phone',
  PIN = 'pin',
  PIN_ACTIVE = 'pinActive',
  PIN_ACTIVE_LG = 'pinActiveLg',
  PIN_DEFAULT = 'pinDefault',
  PIN_SECONDARY_HOVER = 'pinSecondaryHover',
  PIN_STYLING = 'pinStyling',
  PLAY_BUTTON = 'playButton',
  PLUS = 'plus2',
  POLYGON_SELECT = 'polygonSelect',
  PROPERTY_SPECS = 'propertySpecs',
  RESET = 'reset',
  SEARCH = 'search',
  SORT = 'sort',
  SORT_ASCENDING = 'sortAscending',
  SORT_DESCENDING = 'sortDescending',
  SORT_NONE = 'sortNone',
  SQUARE = 'square',
  STAR = 'star',
  STAR_FILLED = 'starFilled',
  THREE_D = 'threeD',
  TRASH = 'trash',
  USER = 'user',
  USER_PROFILE = 'userProfile',
  UTILITIES = 'utilities',
  ZOOM_IN = 'zoomIn',
  ZOOM_OUT = 'zoomOut',
  TRELLO = 'trello',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  TICK = 'tick',
  GREY_USER_ICON = 'greyUserIcon',
  QUESTION_CIRCLE = 'questionCircle',
  EYE = 'eye',
  CIRCLE_CHECK = 'circleCheck',
  CIRCLE_INFO = 'circleInfo',
  CIRCLE_WARNING = 'circleWarning',
  CIRCLE_ERROR = 'circleError',
  MAP_ICON = 'mapIcon',
  CONTACT_ICON = 'contactIcon',
  APP_SWITCHER = 'appSwitcher',
  APP_SWITCHER_HOVER = 'appSwitcherHover',
  USER_ICON = 'userIcon',
  REFRESH = 'refresh',
  EXPORT = 'export',
  CAMERA_ICON = 'cameraIcon',
  VIDEO_ICON = 'videoIcon',
  VIDEO_PLAY_BUTTON = 'videoPlayButton',
  ACTIVE_LOCATION = 'activeLocation',
  PIN_PROPERTY = 'pinProperty',
  POPULATION_LEGEND_HORIZONTAL = 'populationLegendHorizontal',
  POPULATION_LEGEND_VERTICAL = 'populationLegendVertical',
  SHARE = 'share',
}

export interface IconProps {
  classNames?: string;
  name: IconName;
}
