import useIsPublicApp from '@/hooks/useIsPublicApp';
import { TemplateAttributeKey, TemplateTypes, TemplateValue } from '@/types/templates';
import { DATA_DISCLAIMER } from '@/utilities/constants';
import { sortBy } from '@/utilities/functions';
import AssetsCarousel from '@components/AssetsCarousel';
import { ConstrainedTypography } from '@components/ConstrainedTypography';
import ContactBroker from '@components/ContactBroker';
import { Icon, IconName } from '@components/Icon';
import Modal from '@components/Modal/Modal';
import PrimaryInfo from '@components/PrimaryInfo';
import RequestInformationModal from '@components/RequestInformationModal';
import Tab from '@components/Tab';
import Thumbnail from '@components/Thumbnail/Thumbnail';
import { Typography } from '@components/Typography';
import UnitSpecs from '@components/UnitSpecs';
import droneFootageImage from '@images/drone-footage-default-thumbnail.png';
import cn from 'classnames';
import { ReactNode, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import LinkDifference from './LinkDifference';
import './ListingDetails.css';
import { AttributeValueType } from './ListingDetails.utils';
import ListingDetailsMap from './ListingDetailsMap';
import MarketDescription from './MarketDescription';
import { useIsAuthenticated } from '@azure/msal-react';
import Button, { ButtonShape, ButtonVariant } from '@components/Button';

export interface ListingDetailsContentMobileProps {
  data: TemplateValue[];
  isRouteHistoryStackEmpty: boolean;
  isSaved: boolean;
  setShowShareModal: (arg0: boolean) => void;
  setShowRemoveFavoritesModal: (arg0: boolean) => void;
  handleAddingFavoriteListing: (arg0: any) => void;
  handleDownloadFlyer: () => void;
  disableDownloadFlyerButton: boolean;
  marketDescription: AttributeValueType;
  market: AttributeValueType;
  listingId: string;
}
const ListingDetailsContentMobile: React.FC<ListingDetailsContentMobileProps> = ({
  data,
  marketDescription,
  market,
  listingId,
}) => {
  const isAuthenticated = useIsAuthenticated();
  const isPublicApp = useIsPublicApp();
  const [showAssetsGalleryModal, setShowAssetsGalleryModal] = useState(false);
  const [showAssetsCarouselModal, setShowAssetsCarouselModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const [mobileGallerySelectedIndex, setMobileGallerySelectedIndex] = useState<number>(0);
  const LISTING_DETAILS = useMemo(() => {
    return data?.find((children) => children.templateType === TemplateTypes.DetailedListing);
  }, []);

  //Info
  const LISTING_DETAILS__PROPERTY_SPECIFICATIONS = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.PropertySpecifications,
    );
  }, []);

  const NAME = useMemo(() => {
    return LISTING_DETAILS__PROPERTY_SPECIFICATIONS?.attributes?.find(
      (attribute) => attribute.key === TemplateAttributeKey.Name,
    );
  }, []);

  const FULL_ADDRESS = useMemo(() => {
    return LISTING_DETAILS__PROPERTY_SPECIFICATIONS?.attributes?.find(
      (attribute) => attribute.key === TemplateAttributeKey.FullAddress,
    );
  }, []);
  const BUILDING_STATUS = useMemo(() => {
    return LISTING_DETAILS__PROPERTY_SPECIFICATIONS?.attributes?.find(
      (attribute) => attribute.key === TemplateAttributeKey.BuildingStatus,
    );
  }, []);

  const PROPERTY_ID = useMemo(() => {
    return LISTING_DETAILS__PROPERTY_SPECIFICATIONS?.attributes?.find(
      (attribute) => attribute.key === TemplateAttributeKey.PropertyId,
    );
  }, []);

  const DIGITAL_ASSETS = useMemo(() => {
    return LISTING_DETAILS__PROPERTY_SPECIFICATIONS?.attributes?.find(
      (attribute) => attribute.key === TemplateAttributeKey.DigitalAssets,
    );
  }, []);

  const [activeTab, setActiveTab] = useState('Unit');

  //Unit Specs
  const LISTING_DETAILS__UNIT_SPECIFICATIONS = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.UnitSpecifications,
    );
  }, []);

  const UNIT_NAME = useMemo(() => {
    return LISTING_DETAILS__UNIT_SPECIFICATIONS?.attributes?.find(
      (attribute) => attribute.key === TemplateAttributeKey.UnitName,
    );
  }, []);

  //Unit Square Footage Range
  const LISTING_DETAILS__UNIT_SQUAREFOOTAGERANGE = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.SquareFootageRange,
    );
  }, []);

  //Site Plan
  const LISTING_DETAILS__SITE_PLAN = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.SitePlan,
    );
  }, []);
  //Map
  const LISTING_DETAILS__MAP = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.ListingDetailsMap,
    );
  }, []);
  const LONGITUDE = useMemo(() => {
    return LISTING_DETAILS__MAP?.attributes?.find((attribute) => attribute.key === 'LONGITUDE');
  }, []);
  const LATITUDE = useMemo(() => {
    return LISTING_DETAILS__MAP?.attributes?.find((attribute) => attribute.key === 'LATITUDE');
  }, []);

  //Contact Info
  const LISTING_DETAILS__CONTACT_INFORMATION = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.ContactInformation,
    );
  }, []);
  const sitePlanUrl =
    LISTING_DETAILS__SITE_PLAN?.attributes[0].rawValue != null
      ? LISTING_DETAILS__SITE_PLAN?.attributes[0].rawValue
      : '';
  const formatBuildingStatus = (
    value: string | undefined,
  ): { buildingStatus?: string; pillColor?: string } => {
    if (value === undefined) return {};

    let buildingStatus: string | undefined;
    let pillColor: string | undefined;

    switch (value) {
      case 'BUILD_TO_SUIT':
        buildingStatus = 'build to suit opportunity';
        pillColor = 'bg-solar-700';
        break;
      case 'NEW_SPECULATIVE_DEVELOPMENT':
        buildingStatus = isPublicApp ? 'new development' : 'new class a development';
        pillColor = 'bg-solar-700';
        break;
      case 'UNDER_CONSTRUCTION':
        buildingStatus = isPublicApp ? 'new development' : 'under construction';
        pillColor = 'bg-solar-700';
        break;
      case 'Operational Industrial':
        buildingStatus = isPublicApp ? 'existing' : 'industrial';
        pillColor = 'bg-rust-600';
        break;
      case 'Link Parks':
        buildingStatus = !isPublicApp ? 'link parks' : undefined;
        pillColor = 'bg-future-300';
        break;
    }

    return { buildingStatus, pillColor };
  };

  const { buildingStatus, pillColor } = formatBuildingStatus(BUILDING_STATUS?.rawValue);

  const formatContactInformation = (
    key: string,
    templateInfo: TemplateValue | undefined,
    propertyType: string,
  ) => {
    if (templateInfo === undefined) return;
    const brokerName = templateInfo.attributes.find((attribute) => attribute.key === 'BROKER_NAME');
    const brokerPhone = templateInfo.attributes.find(
      (attribute) => attribute.key === 'BROKER_PHONE_NUMBER',
    );
    const brokerShop = templateInfo.attributes.find((attribute) => attribute.key === 'BROKER_SHOP');
    const brokerEmail = templateInfo.attributes.find(
      (attribute) => attribute.key === 'BROKER_EMAIL',
    );
    const linkRepEmail = templateInfo.attributes.find(
      (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_EMAIL',
    );
    const linkRepName = templateInfo.attributes.find(
      (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_NAME',
    );
    const linkRepPhone = templateInfo.attributes.find(
      (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_PHONE_NUMBER',
    );
    const brokerLogo = templateInfo.attributes.find(
      (attribute) => attribute.key === 'BROKER_SHOP_LOGO',
    );
    const linkRepPhoto = templateInfo.attributes.find(
      (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_HEADSHOT',
    );
    let nameToShow = brokerName?.rawValue ?? '--',
      emailToShow = brokerEmail?.rawValue ?? '--',
      phoneToShow = brokerPhone?.rawValue ?? '--',
      shopToShow = brokerShop?.rawValue ?? '--',
      photoToShow = brokerLogo?.rawValue ?? '--',
      linkRepNameToShow = linkRepName?.rawValue ?? '--',
      linkRepEmailToShow = linkRepEmail?.rawValue ?? '--',
      linkRepPhoneToShow = linkRepPhone?.rawValue ?? '--',
      linkRepPhotoToShow = linkRepPhoto?.rawValue ?? '--';

    let value;
    switch (key) {
      case 'nameToShow':
        value = nameToShow;
        break;
      case 'emailToShow':
        value = emailToShow;
        break;
      case 'phoneToShow':
        value = phoneToShow;
        break;
      case 'shopToShow':
        value = shopToShow;
        break;
      case 'photoToShow':
        value = photoToShow;
        break;
      case 'brokerEmail':
        value = brokerEmail ? brokerEmail?.icon : linkRepEmail?.icon;
        break;
      case 'brokerPhone':
        value = brokerPhone ? brokerPhone?.icon : linkRepPhone?.icon;
        break;
      case 'linkRepNameToShow':
        value = linkRepNameToShow;
        break;
      case 'linkRepEmailToShow':
        value = linkRepEmailToShow;
        break;
      case 'linkRepPhoneToShow':
        value = linkRepPhoneToShow;
        break;
      case 'linkRepPhotoToShow':
        value = linkRepPhotoToShow;
        break;
    }
    return value;
  };

  // Get broker and ll rep details
  const brokerName = formatContactInformation(
    'nameToShow',
    LISTING_DETAILS__CONTACT_INFORMATION,
    LISTING_DETAILS?.propertyType!,
  );
  const brokerEmail = formatContactInformation(
    'emailToShow',
    LISTING_DETAILS__CONTACT_INFORMATION,
    LISTING_DETAILS?.propertyType!,
  );
  const brokerPhone = formatContactInformation(
    'phoneToShow',
    LISTING_DETAILS__CONTACT_INFORMATION,
    LISTING_DETAILS?.propertyType!,
  );
  const brokerShop = formatContactInformation(
    'shopToShow',
    LISTING_DETAILS__CONTACT_INFORMATION,
    LISTING_DETAILS?.propertyType!,
  );
  const brokerLogo =
    formatContactInformation(
      'photoToShow',
      LISTING_DETAILS__CONTACT_INFORMATION,
      LISTING_DETAILS?.propertyType!,
    ) == '--'
      ? undefined
      : formatContactInformation(
          'photoToShow',
          LISTING_DETAILS__CONTACT_INFORMATION,
          LISTING_DETAILS?.propertyType!,
        );

  const emailIcon = formatContactInformation(
    'brokerEmail',
    LISTING_DETAILS__CONTACT_INFORMATION,
    LISTING_DETAILS?.propertyType!,
  );
  const phoneIcon = formatContactInformation(
    'brokerPhone',
    LISTING_DETAILS__CONTACT_INFORMATION,
    LISTING_DETAILS?.propertyType!,
  );
  const linkRepName = formatContactInformation(
    'linkRepNameToShow',
    LISTING_DETAILS__CONTACT_INFORMATION,
    LISTING_DETAILS?.propertyType!,
  );
  const linkRepEmail = formatContactInformation(
    'linkRepEmailToShow',
    LISTING_DETAILS__CONTACT_INFORMATION,
    LISTING_DETAILS?.propertyType!,
  );
  const linkRepPhone = formatContactInformation(
    'linkRepPhoneToShow',
    LISTING_DETAILS__CONTACT_INFORMATION,
    LISTING_DETAILS?.propertyType!,
  );
  const linkRepPhoto =
    formatContactInformation(
      'linkRepPhotoToShow',
      LISTING_DETAILS__CONTACT_INFORMATION,
      LISTING_DETAILS?.propertyType!,
    ) == '--'
      ? undefined
      : formatContactInformation(
          'linkRepPhotoToShow',
          LISTING_DETAILS__CONTACT_INFORMATION,
          LISTING_DETAILS?.propertyType!,
        );

  const renderActiveTabContent = (): ReactNode => {
    const orderPrimaryInfoMobile = [
      'MARKET',
      'SUBMARKET',
      'CONTACT_US_OR_DATE_AVAILABLE',
      'BUILDING_SQUARE_FOOTAGE',
      'ACREAGE',
    ];
    const orderUnitSpecsMobile = [
      'UNIT_NAME',
      'UNIT_SQUARE_FOOTAGE_RANGE',
      'UNIT_SQUARE_FOOTAGE',
      'UNIT_OFFICE_SQUARE_FOOTAGE_OR_BUILD_TO_SUIT',
      'CLEAR_HEIGHT',
      'LOADING_DOCKS',
      'DRIVE_IN_DOORS',
      'TRUCK_COURT_DEPTH',
      'TRAILER_PARKING_AVAILABLE',
      'RAIL_SERVED',
      'ESFR',
    ];
    let component;
    switch (activeTab) {
      case 'Unit':
        component = (
          <div id="Info-Mobile">
            <PrimaryInfo
              propertyId={listingId}
              attributes={sortBy(
                LISTING_DETAILS__PROPERTY_SPECIFICATIONS?.attributes,
                orderPrimaryInfoMobile,
              )}
              propertyType={LISTING_DETAILS?.propertyType}
              isRouteHistoryStackEmpty={false}
              isSaved={false}
              setShowShareModal={() => null}
              setShowRemoveFavoritesModal={() => null}
              handleAddingFavoriteListing={() => null}
              handleDownloadFlyer={() => null}
              downloading={false}
            />
            {isPublicApp && <div className="bg-slate-100 h-[1px] md:w-[93%] my-4"></div>}
            {BUILDING_STATUS?.rawValue !== 'BUILD_TO_SUIT' ? (
              <UnitSpecs
                attributes={sortBy(
                  LISTING_DETAILS__UNIT_SPECIFICATIONS?.attributes,
                  orderUnitSpecsMobile,
                )}
                propertyType={LISTING_DETAILS?.propertyType}
                isMobileView={true}
              />
            ) : (
              <div className="grid grid-cols-2 mb-[0.9375rem] md:w-[27.3125rem]">
                <Typography variant="body-3" className="!font-normal !leading-5">
                  Available Size
                </Typography>
                <Typography variant="body-3" className="!font-medium !leading-5">
                  {LISTING_DETAILS__UNIT_SQUAREFOOTAGERANGE?.attributes[0].value}
                </Typography>
              </div>
            )}
            <Typography variant="body-4" className=" mt-5 text-[#777777]">
              {DATA_DISCLAIMER}
            </Typography>
          </div>
        );

        break;
      case 'SitePlanMap':
        component = (
          <div id="Map-View-Mobile" className="flex flex-col gap-6">
            {sitePlanUrl && (
              <div className="[&_.mapboxgl-map]:rounded-[0.4375rem] w-full  pointer-events-none drop-shadow-lg listing-map">
                <img alt="" src={sitePlanUrl} />
              </div>
            )}
            <div className="[&_.mapboxgl-map]:rounded-[0.4375rem] w-full h-[40rem] pointer-events-none drop-shadow-lg listing-map">
              <ListingDetailsMap latitude={LATITUDE?.rawValue} longitude={LONGITUDE?.rawValue} />
            </div>
          </div>
        );
        break;
      case 'Market':
        component = <MarketDescription market={market} marketDescription={marketDescription} />;
        break;
      case 'Contacts':
        component = (
          <>
            <div
              id="contact-broker-mobile"
              className={'flex sm:justify-start md:justify-center w-full relative'}>
              <ContactBroker
                brokerName={brokerName}
                brokerEmail={brokerEmail}
                brokerPhone={brokerPhone}
                brokerShop={brokerShop}
                brokerLogo={brokerLogo}
                emailIcon={emailIcon}
                phoneIcon={phoneIcon}
                linkRepName={linkRepName}
                linkRepEmail={linkRepEmail}
                linkRepPhone={linkRepPhone}
                linkRepPhoto={linkRepPhoto}
                isBigScreen={false}
              />
            </div>
            {!isAuthenticated && (
              <div id="contact-broker-section" className="pt-6">
                <Button
                  label="Request Information"
                  onClick={() => setShowContactModal(true)}
                  variant={ButtonVariant.DEFAULT_OUTLINE}
                  shape={ButtonShape.CIRCLE}
                  buttonClassNames="border-none uppercase !h-[2.65rem]"
                  labelClassNames="!text-base !font-semibold !leading-[18px] !tracking-[1px]"
                />
              </div>
            )}
          </>
        );
        break;
    }
    return component;
  };

  const customCloseButton = (
    <button
      className="absolute flex space-x-[0.5625rem] hover:opacity-50 right-4 text-white-100 top-4"
      onClick={() => setShowAssetsGalleryModal(false)}>
      <Icon name={IconName.CLOSE} />
      <Typography variant="button">Close</Typography>
    </button>
  );

  const customMediaBanner = (
    <div
      role="none"
      className="absolute bottom-4 left-6 z-10 rounded-sm px-5 py-3 bg-white-100 border-slate-300 !h-[20px] !w-[70px] top-4"
      onClick={() => setShowAssetsGalleryModal(true)}>
      <div className="absolute flex left-[5px] top-[6px] text-white-100">
        <Icon
          classNames="!h-[0.731rem] !w-[0.813rem] margin-left-[5px]"
          name={IconName.CAMERA_ICON}
        />
        <Typography variant="body-2" className="text-black absolute top-[-4px] left-[15px]">
          {DIGITAL_ASSETS?.rawValue.imageCount}
        </Typography>
        <Icon classNames="!h-[0.731rem] !w-[0.813rem] ml-[1.2rem]" name={IconName.VIDEO_ICON} />
        <Typography variant="body-2" className="text-black absolute top-[-4px] left-[47px]">
          {DIGITAL_ASSETS?.rawValue.videoCount}
        </Typography>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col md:mx-auto">
      <div id="Property-Image-Mobile" className="min-h-[12rem]">
        <Thumbnail
          propertyImageUrl={DIGITAL_ASSETS?.value}
          onClick={() => setShowAssetsGalleryModal(true)}>
          <div id="primary-info-badges">
            <div className="absolute z-10 flex top-6 left-6">
              {buildingStatus != undefined && (
                <Typography
                  variant="body-4"
                  className={cn(
                    'p-2 mr-2 rounded-[40px] bg-solar-700 uppercase !font-medium !leading-[14px] !tracking-[1px]',
                    pillColor,
                  )}>
                  {buildingStatus}
                </Typography>
              )}
            </div>
          </div>
          <div
            role="none"
            className="absolute bottom-4 left-6 z-10 rounded-sm px-5 py-3 bg-white-100 border-slate-300 !h-[20px] !w-[70px]"
            onClick={() => setShowAssetsGalleryModal(true)}>
            <div className="absolute flex left-[5px] top-[6px] text-white-100">
              <Icon
                classNames="!h-[0.731rem] !w-[0.813rem] margin-left-[5px]"
                name={IconName.CAMERA_ICON}
              />
              <Typography variant="body-2" className="text-black absolute top-[-4px] left-[15px]">
                {DIGITAL_ASSETS?.rawValue.imageCount}
              </Typography>
              <Icon
                classNames="!h-[0.731rem] !w-[0.813rem] ml-[1.2rem]"
                name={IconName.VIDEO_ICON}
              />
              <Typography variant="body-2" className="text-black absolute top-[-4px] left-[47px]">
                {DIGITAL_ASSETS?.rawValue.videoCount}
              </Typography>
            </div>
          </div>
        </Thumbnail>
        {showAssetsGalleryModal && (
          <Modal
            classNames="!bg-transparent !p-0 !rounded-none"
            customCloseBtn={customCloseButton}
            show
            customAllMediaButton={customMediaBanner}
            isSmallScreen={isSmallScreen}>
            <div className="h-[93vh] overflow-y-auto scrollbar-hide">
              {DIGITAL_ASSETS?.rawValue.assets.map((asset: any, index: number) =>
                asset.type === 'video' ? (
                  <div
                    role="none"
                    key={asset.url}
                    className="relative w-full mt-[10px]"
                    onClick={() => {
                      setShowAssetsCarouselModal(true);
                      setShowAssetsGalleryModal(false);
                      setMobileGallerySelectedIndex(index);
                    }}>
                    <img className="!w-[inherit]" alt="" src={droneFootageImage} />
                  </div>
                ) : (
                  <div
                    role="none"
                    key={asset.url}
                    className="relative w-full mt-[10px]"
                    onClick={() => {
                      setShowAssetsCarouselModal(true);
                      setShowAssetsGalleryModal(false);
                      setMobileGallerySelectedIndex(index);
                    }}>
                    <img alt="" src={asset.url} />
                  </div>
                ),
              )}
            </div>
          </Modal>
        )}
        {showAssetsCarouselModal && !showAssetsGalleryModal && (
          <AssetsCarousel
            initialSlideIndex={mobileGallerySelectedIndex}
            assets={DIGITAL_ASSETS?.rawValue.assets}
            onClose={() => setShowAssetsCarouselModal(false)}
            onAllMediaClick={() => {
              setShowAssetsCarouselModal(false);
              setShowAssetsGalleryModal(true);
            }}
            displayCustomMediaButton={true}
          />
        )}
      </div>
      <div className="flex flex-col gap-4 px-6">
        <div className="flex-col  mt-[13px] space-y-2" id="property-name-address-mobile">
          <ConstrainedTypography className="mb-2" variant="h5" width="max-w-[40.625rem]">
            {NAME?.value}
          </ConstrainedTypography>
          <Typography variant="body-2" className="mb-2 text-slate-300">
            {FULL_ADDRESS?.value}
          </Typography>
        </div>
        {!isAuthenticated && (
          <div id="contact-broker-section">
            <Button
              label="Request Information"
              onClick={() => setShowContactModal(true)}
              variant={ButtonVariant.DEFAULT_OUTLINE}
              shape={ButtonShape.CIRCLE}
              buttonClassNames="border-none uppercase !h-[2.65rem]"
              labelClassNames="!text-base !font-semibold !leading-[18px] !tracking-[1px]"
            />
          </div>
        )}
        <div id="property-tab-group">
          <div id="property-tab-group-header">
            <div id="property-tab-group-headers" className="flex space-x-8">
              <Tab
                label={'Unit'}
                active={activeTab === 'Unit'}
                onClick={() => setActiveTab('Unit')}
              />
              {sitePlanUrl && (
                <Tab
                  label={'Site Plan & Map'}
                  active={activeTab === 'SitePlanMap'}
                  onClick={() => setActiveTab('SitePlanMap')}
                />
              )}
              <Tab
                label={'Contacts'}
                active={activeTab === 'Contacts'}
                onClick={() => setActiveTab('Contacts')}
              />
              {marketDescription && (
                <Tab
                  label={'Market'}
                  active={activeTab === 'Market'}
                  onClick={() => setActiveTab('Market')}
                />
              )}
            </div>
          </div>

          <div id="property-tab-group-content" className="py-6 md:pt-8 min-h-[10rem]">
            {renderActiveTabContent()}
          </div>
        </div>
      </div>
      <LinkDifference />
      {showContactModal && (
        <RequestInformationModal
          onClose={() => {
            setShowContactModal(false);
          }}
          modalData={{
            listingName: NAME?.value,
            listingAddress: FULL_ADDRESS?.value,
            listingId: listingId,
            propertyId: PROPERTY_ID?.value,
            unitName: UNIT_NAME?.value,
            brokerName: brokerName,
            brokerEmail: brokerEmail,
            brokerPhone: brokerPhone,
            brokerShop: brokerShop,
            brokerLogo: brokerLogo,
            emailIcon: emailIcon,
            phoneIcon: phoneIcon,
            linkRepName: linkRepName,
            linkRepEmail: linkRepEmail,
            linkRepPhone: linkRepPhone,
            linkRepPhoto: linkRepPhoto,
            isBigScreen: true,
          }}
        />
      )}
    </div>
  );
};

export default ListingDetailsContentMobile;
