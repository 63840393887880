import cn from 'classnames';
import React from 'react';

import { isIndustrialOrPark } from '@/utilities/constants';
import { ConstrainedTypography } from '@components/ConstrainedTypography';
import { Icon } from '@components/Icon';
import { Typography } from '@components/Typography';
import { PrimarySpecificationProps } from './PrimaryInfo.types';

const PrimarySpecification: React.FC<PrimarySpecificationProps> = ({
  className,
  iconName,
  label,
  value,
  propertyType,
}) => {
  return isIndustrialOrPark(propertyType) && label?.toLowerCase() === 'unit size' ? null : (
    <div
      className={cn([
        'flex items-center sm:mb-0 sm:mr-0 md:mb-1 md:mr-0 lg:mb-3 lg:mr-2',
        className,
      ])}>
      <div className="flex items-center basis-1/2 grow-0">
        {iconName && <Icon classNames="mr-2 !w-6 !h-6" name={iconName} />}
        <Typography
          variant="body-3"
          className="md:font-light md:leading-5 md:text-[14px] md:mr-1 lg:mr-5 sm:font-normal sm:text-[12px] sm:leading-4">
          {label}:
        </Typography>
      </div>
      <ConstrainedTypography variant="body-3" className="!font-medium !leading-5 max-w-[10rem]">
        {value}
      </ConstrainedTypography>
    </div>
  );
};

export default PrimarySpecification;
