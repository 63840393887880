export interface ButtonProps {
  buttonClassNames?: string;
  labelClassNames?: string;
  classNames?: string;
  disabled?: boolean;
  error?: boolean;
  href?: string;
  Icon?: JSX.Element;
  iconPosition?: IconPosition;
  label?: string;
  onClick?: () => void;
  shape?: ButtonShape;
  variant?: ButtonVariant;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
  children?: string | JSX.Element | JSX.Element[];
}

export enum ButtonShape {
  CIRCLE = 'CIRCLE',
  SQUARE = 'SQUARE',
}

export enum ButtonVariant {
  DEFAULT_OUTLINE = 'defaultOutline',
  DEFAULT_OUTLINE_DARK_THEME = 'defaultOutlineDarkTheme',
  NO_FILL = 'noFill',
  NO_FILL_WHITE = 'noFillWhite',
  PRIMARY_ONE = 'primaryOne',
  PRIMARY_TWO = 'primaryTwo',
  REVEAL_ACTION = 'revealAction',
  SAVE_SEARCH = 'saveSearch',
  REPORT_HP = 'reportMapPage',
  MOBILE_ACTION = 'mobileAction',
}

export type FormatVariantStateClassNamesFunc = (
  disabled: boolean,
  error: boolean,
  variant: ButtonVariant,
) => string;

export enum IconPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
