import useIsBigScreen from '@/hooks/useIsBigScreen';
import { useIsAuthenticated } from '@azure/msal-react';
import Button, { ButtonShape, ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import RequestInformationModal from '@components/RequestInformationModal';
import { Typography } from '@components/Typography';
import { useMemo, useState } from 'react';
import './ContactBroker.css';
import { ContactBrokerProps } from './ContactBroker.types';

export const getUserInitials = (name: string) => {
  let userInitials = `${name.slice(0, 1).toUpperCase()}`;
  const nameArr = name.split(' ');
  if (nameArr.length > 1) {
    userInitials += `${nameArr[nameArr.length - 1].slice(0, 1).toUpperCase()}`;
  }

  return userInitials;
};
const ContactCard: React.FC<{
  name: string;
  email: string;
  phone: string;
  photo: string;
  shop: string;
}> = ({ name, phone, photo, shop }) => {
  const userIcon = useMemo(() => {
    if (photo) {
      return (
        <div className="w-16 h-16">
          <img src={photo} alt={name} className="rounded-full" data-testid="photo-img" />
        </div>
      );
    } else if (name) {
      const userInitials = getUserInitials(name);
      const sanitizedUserInitials = encodeURI(userInitials);
      return (
        <div>
          {userInitials && userInitials !== '-' && userInitials !== '--' ? (
            <div className="flex items-center justify-center w-16 h-16 rounded-full bg-solar-100">
              <Typography
                variant="h6"
                className="font-lg text-sm !font-normal text-white-100"
                data-testid="user-initials">
                {sanitizedUserInitials}
              </Typography>
            </div>
          ) : (
            <Icon classNames="!h-16 !w-16" name={IconName.GREY_USER_ICON} />
          )}
        </div>
      );
    }
  }, [photo, name]);
  return (
    <div className="flex flex-row items-center gap-6">
      {userIcon}
      <div className="flex flex-col gap-[0.1rem]">
        <Typography variant="body-3" className="!font-bold !leading-5">
          {name}
        </Typography>
        <Typography variant="body-3" className="!font-normal !leading-5">
          {shop}
        </Typography>
        <Typography variant="body-3" className="!font-normal !leading-5">
          {phone}
        </Typography>
      </div>
    </div>
  );
};

const ContactBroker: React.FC<ContactBrokerProps> = ({
  brokerName,
  brokerEmail,
  brokerPhone,
  brokerLogo,
  brokerShop,
  linkRepName,
  linkRepEmail,
  linkRepPhone,
  linkRepPhoto,
  emailIcon,
  phoneIcon,
  listingId,
  propertyId,
  unitName,
  listingName,
  listingAddress,
}) => {
  const isBigScreen = useIsBigScreen();
  const isAuthenticated = useIsAuthenticated();
  const [showContactModal, setShowContactModal] = useState(false);
  return (
    <>
      {showContactModal && (
        <RequestInformationModal
          onClose={() => {
            setShowContactModal(false);
          }}
          modalData={{
            listingName: listingName,
            listingAddress: listingAddress,
            listingId: listingId,
            propertyId: propertyId,
            unitName: unitName,
            brokerName: brokerName,
            brokerEmail: brokerEmail,
            brokerPhone: brokerPhone,
            brokerShop: brokerShop,
            brokerLogo: brokerLogo,
            emailIcon: emailIcon,
            phoneIcon: phoneIcon,
            linkRepName: linkRepName,
            linkRepEmail: linkRepEmail,
            linkRepPhone: linkRepPhone,
            linkRepPhoto: linkRepPhoto,
            isBigScreen: true,
          }}
        />
      )}
      <div className="flex flex-col items-start gap-6">
        <Typography
          className="text-slate-600 !leading-[18px] tracking-[2px] uppercase"
          variant="subtitle-3">
          Leasing Contacts
        </Typography>
        <div className="flex flex-col items-start gap-2">
          <ContactCard
            name={brokerName}
            email={brokerEmail}
            phone={brokerPhone}
            photo={brokerLogo}
            shop={brokerShop}
          />
        </div>

        <div className="flex flex-col items-start gap-2">
          <ContactCard
            name={linkRepName}
            email={linkRepEmail}
            phone={linkRepPhone}
            photo={linkRepPhoto}
            shop={'Link Logistics'}
          />
        </div>
        {isBigScreen && !isAuthenticated && (
          <Button
            label="Request Information"
            onClick={() => setShowContactModal(true)}
            variant={ButtonVariant.DEFAULT_OUTLINE}
            shape={ButtonShape.CIRCLE}
            buttonClassNames="border-none uppercase !h-[2.65rem]  w-full"
            labelClassNames="!text-base !font-semibold !leading-[18px] !tracking-[1px]"
          />
        )}
      </div>
    </>
  );
};

export default ContactBroker;
